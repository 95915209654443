<template>
	<div class="HSMask" style="z-index: 10;">
		<div class="HSDialog">
			<div class="HSDialogTitleLine">
				<div class="classify1">
					<div class="classify1Item" :class="code1 === nav1.code ? 'active' : ''" v-for="(nav1, index1) in classify"
						:key="index1" @click="changeNav1(nav1)">
						{{ nav1.name }}
					</div>
				</div>
				<!-- <div class="HSDialogTitle"></div> -->
				<img class="HSDialogCloseImg" src="../assets/img/HS/close.png" @click="close" />
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div class="HSDialogContent">
				<div class="contentL">

					<div v-show="code1 === nav1.code" v-for="(nav1, index1) in classify" :key="index1" class="classifyBox">
						<div>
							<div class="classify2" v-for="(nav2, index2) in nav1.itemList" :key="index2">
								<div class="classify2Item" :class="code2 === nav2.code ? 'active' : ''" @click="changeNav2(nav2)">
									{{ nav2.name }}
								</div>
								<div class="classify3">
									<div class="classify3Item" :class="code3 === nav3.code ? 'active' : ''"
										v-for="(nav3, index3) in nav2.itemList" :key="index3" @click="changeNav3(nav2, nav3)">
										{{ nav3.name }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="contentR">
					<div style="padding:10px;">
						<el-input v-model="keyword" @keyup.enter.native="changeKeyword" style="width:200px;"
							placeholder="请输入关键字"></el-input>
						<el-button type="primary" @click="changeKeyword">搜索</el-button>
						<span style="margin-left:15px;" v-if="code1 != 'pettype'">适用宠物：</span>
						<el-radio-group v-if="code1 != 'pettype'" v-model="applyType" @change="changeApplyType">
							<el-radio :label="''">不限</el-radio>
							<el-radio :label="'cat'">猫猫</el-radio>
							<el-radio :label="'dog'">狗狗</el-radio>
							<el-radio :label="'yichong'">其他</el-radio>
						</el-radio-group>
					</div>
					<div class="productTableBox">
						<!-- 商品 -->
						<el-table v-if="code1 === 'goodstype'" :data="tableDataGoods" height="100%">
							<el-table-column label="条码" min-width="100">
								<template slot-scope="scope">
									<el-checkbox :value="selectedArr.some(a => { return scope.row.goodsId === a.newData.productId })"
										@change="changeCheckbox(scope.row)"
										:disabled="scope.row.enableNoStockSale === false && scope.row.currentCount < 1 && checkStock">
										{{ scope.row.barCode }}
									</el-checkbox>
								</template>
							</el-table-column>
							<el-table-column label="名称" min-width="150">
								<template slot-scope="scope">
									<div v-if="$public.isNull(scope.row.cover)">
										{{ scope.row.title }}
									</div>
									<el-tooltip v-else placement="top" effect="light">
										<div slot="content">
											<img :src="scope.row.cover" style="width:300px;" />
										</div>
										<div>
											{{ scope.row.title }}
										</div>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column label="分类" min-width="60">
								<template slot-scope="scope">
									{{ scope.row.secondCategoryInfo.name }}
								</template>
							</el-table-column>
							<el-table-column label="规格" min-width="80">
								<template slot-scope="scope">
									{{ scope.row.model }}
								</template>
							</el-table-column>
							<el-table-column label="售价" min-width="80">
								<template slot-scope="scope">
									{{ scope.row.salePrice }}
								</template>
							</el-table-column>
							<el-table-column label="库存" min-width="80">
								<template slot-scope="scope">
									{{ scope.row.currentCount }}
								</template>
							</el-table-column>
						</el-table>
						<!-- 服务 -->
						<el-table v-if="code1 === 'servicetype'" :data="tableDataService" height="100%">
							<el-table-column label="名称" min-width="80">
								<template slot-scope="scope">
									<el-checkbox :value="selectedArr.some(a => { return scope.row.serviceId === a.newData.productId })"
										@change="changeCheckbox(scope.row)">
										{{ scope.row.title }}
									</el-checkbox>
								</template>
							</el-table-column>
							<el-table-column label="条码" min-width="100">
								<template slot-scope="scope">
									{{ scope.row.barCode }}
								</template>
							</el-table-column>
							<el-table-column label="分类" min-width="80">
								<template slot-scope="scope">
									{{ scope.row.secondCategoryInfo.name }}
								</template>
							</el-table-column>
							<!-- <el-table-column label="规格" min-width="80">
								<template slot-scope="scope">
									{{scope.row.model}}
								</template>
							</el-table-column> -->
							<el-table-column label="售价" min-width="80">
								<template slot-scope="scope">
									{{ scope.row.salePrice }}
								</template>
							</el-table-column>
						</el-table>
						<!-- 套餐 -->
						<el-table v-if="code1 === 'combotype'" :data="tableDataGroup" height="100%">
							<el-table-column label="名称" min-width="80">
								<template slot-scope="scope">
									<el-checkbox :value="selectedArr.some(a => { return scope.row.groupId === a.newData.productId })"
										@change="changeCheckbox(scope.row)">
										{{ scope.row.title }}
									</el-checkbox>
								</template>
							</el-table-column>
							<el-table-column label="条码" min-width="100">
								<template slot-scope="scope">
									{{ scope.row.barCode }}
								</template>
							</el-table-column>
							<el-table-column label="分类" min-width="80">
								<template slot-scope="scope">
									{{ scope.row.secondCategoryInfo.name }}
								</template>
							</el-table-column>
							<!-- <el-table-column label="规格" min-width="80">
								<template slot-scope="scope">
									{{scope.row.model}}
								</template>
							</el-table-column> -->
							<el-table-column label="售价" min-width="80">
								<template slot-scope="scope">
									{{ scope.row.salePrice }}
								</template>
							</el-table-column>
						</el-table>
						<!-- 虚拟 -->
						<el-table v-if="code1 === 'virtualtype'" :data="tableDataVirtual" height="100%">
							<el-table-column label="名称" min-width="80">
								<template slot-scope="scope">
									<div v-if="$public.isNull(scope.row.cover)">
										<el-checkbox :value="selectedArr.some(a => { return scope.row.virtualId === a.newData.productId })"
											@change="changeCheckbox(scope.row)">
											{{ scope.row.title }}
										</el-checkbox>
									</div>
									<el-tooltip v-else placement="top" effect="light">
										<div slot="content">
											<img :src="scope.row.cover" style="width:300px;" />
										</div>
										<div>
											<el-checkbox
												:value="selectedArr.some(a => { return scope.row.virtualId === a.newData.productId })"
												@change="changeCheckbox(scope.row)">
												{{ scope.row.title }}
											</el-checkbox>
										</div>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column label="条码" min-width="100">
								<template slot-scope="scope">
									{{ scope.row.barCode }}
								</template>
							</el-table-column>
							<el-table-column label="分类" min-width="80">
								<template slot-scope="scope">
									{{ scope.row.secondCategoryInfo.name }}
								</template>
							</el-table-column>
							<!-- <el-table-column label="规格" min-width="80">
								<template slot-scope="scope">
									{{scope.row.model}}
								</template>
							</el-table-column> -->
							<el-table-column label="售价" min-width="80">
								<template slot-scope="scope">
									{{ scope.row.salePrice }}
								</template>
							</el-table-column>
						</el-table>
						<!-- 优惠券 -->
						<el-table v-if="code1 === 'coupontype'" :data="tableDataCoupon" height="100%">
							<el-table-column label="名称" min-width="80">
								<template slot-scope="scope">
									<el-checkbox :value="selectedArr.some(a => { return scope.row.virtualId === a.newData.productId })"
										@change="changeCheckbox(scope.row)">
										{{ scope.row.title }}
									</el-checkbox>
								</template>
							</el-table-column>
							<el-table-column label="条码" min-width="100">
								<template slot-scope="scope">
									{{ scope.row.barCode }}
								</template>
							</el-table-column>
							<el-table-column label="分类" min-width="80">
								<template slot-scope="scope">
									{{ scope.row.secondCategoryInfo.name }}
								</template>
							</el-table-column>
							<!-- <el-table-column label="规格" min-width="80">
								<template slot-scope="scope">
									{{scope.row.model}}
								</template>
							</el-table-column> -->
							<el-table-column label="售价" min-width="80">
								<template slot-scope="scope">
									{{ scope.row.totalMoney }}
								</template>
							</el-table-column>
						</el-table>
							<div class="PetList" v-if="code1 === 'pettype'" height="100%">
								<div class="petItem"  v-for="(item,index) in tableDataPet" :key="index" :class="{ isActive:selectedArr.some(a => { return item.petId === a.newData.productId })}" @click="changeCheckbox(item,index)" >
									<div  class="el-image" style="height: 88px; width: 88px; border-radius: 6px;"><img
											:src="item.cover" v-if="item.cover"
											class="el-image__inner" style="object-fit: cover;"></div>
									<div class="petInfo">
										<p>{{ item.nickName }}</p>
										<p class="petInfoT">
											<span>{{ item.secondCategoryInfo.name }}</span>
											<span class="f-r">{{ item.petSex==1?'弟弟':'妹妹' }}</span>
										</p>
										<p class="petInfoT">
											<span 	v-if="item.bronDate">{{ $public.calculatePetAge($public.FTime(item.bronDate))[0] }}岁{{ $public.calculatePetAge($public.FTime(item.bronDate))[1]
									}}月</span>
											<span> 零售价 <b>¥{{ item.salePrice }}.00</b></span>
								</p>
									</div>
								</div>
							</div>
					</div>
					<div class="paging">
						<page :pageSizes="pageSizes"	@size-change="handleSizeChange" :pageIndex="page.pageIndex" :pageSize="page.pageSize" :total="page.total" @change="changePage"></page>
					</div>
					<div class="selectedProduct">
						<el-tag v-for="(goods, index) in selectedArr" :key="index" style="margin:1px 3px;" closable
							@close="closeTag(index)">
							{{ goods.newData.productName }}
						</el-tag>
					</div>
				</div>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div style="height: 80px;"></div>
			<div class="HSDialogButtonLine">
				<div class="HSDialogButtonCancel" @click="close">取消</div>
				<div class="HSDialogButtonConfirm" @click="submit">确认</div>
			</div>
		</div>
	</div>
</template>
<script>
import page from "./page.vue"
export default {
	components: { page },
	data() {
		return {
			code1: "",
			code2: "",
			code3: "",
			categoryId: '',
			classify: [],
			// search
			keyword: '',
			applyType: '',
			// table
			page: {
				pageIndex: 1,
				pageSize: 9,
				total: 0,
			},
			pageSizes: [10, 15,30,60],
			tableDataGoods: [],
			tableDataService: [],
			tableDataGroup: [],
			tableDataVirtual: [],
			tableDataCoupon: [],
			tableDataPet:[],

			// 已选择的商品列表
			selectedArr: [],
			isChange:-1,
		}
	},
	computed: {

	},
	props: {
		checkStock: {
			default: false
		},
		tree: {
			default: () => {
				return []
			}
		},
		// [
		// 	{
		// 		newData:{productId:111},
		// 		originalData:{},
		// 	},
		// 	{
		// 		newData:{productId:111},
		// 		originalData:{},
		// 	}
		// ]
		selectedList: {
			default: () => {
				return []
			}
		},
		ignoreGoods: {   //忽略商品
			default: ''
		}
	},
	mounted() {
		for (let i = 0; i < this.selectedList.length; i++) {
			this.selectedArr.push({
				newData: this.selectedList[i].newData,
				originalData: null,
			})
		}
		this.getClassify();
	},
	methods: {
		// 关闭
		close() {
			this.$emit("submit", { type: "close", data: [] })
		},
		// 提交
		submit() {
			if (this.selectedArr.length === 0) {
				this.$message({
					message: '请选择产品项目',
					type: 'error'
				});
			} else {
				let d = []
				for (let i = 0; i < this.selectedArr.length; i++) {
					let item = this.selectedArr[i]
					for (let a = 0; a < this.selectedList.length; a++) {
						if (this.selectedArr[i].newData.productId === this.selectedList[a].newData.productId) {
							item.newData = this.selectedList[a].newData
						}
					}
					d.push(item)
				}
				this.$emit("submit", { type: 'success', data: d })
			}
		},
		// 获取一级分类
		changeNav1(nav1) {
			console.log(nav1,'nav11111');
			this.code1 = nav1.code
			this.code2 = ''
			this.code3 = ''
			this.categoryId = ''
			this.page.pageIndex = 1;
			this.getTableData()
		},
		// 获取二级分类
		changeNav2(nav2) {
			this.code2 = nav2.code
			this.code3 = ''
			this.categoryId = nav2.id
			this.page.pageIndex = 1;
			this.getTableData()
		},
		// 获取三级分类
		changeNav3(nav2, nav3) {
			this.code2 = nav2.code
			this.code3 = nav3.code
			this.categoryId = nav3.id
			this.page.pageIndex = 1;
			this.getTableData()
		},
		getClassify() {
			let params = {
				DescSore: false,
				code: "producttype",
			}
			this.$http.get("/cms/System/GetSystemDiction", params).then(res => {
				if (res.code === 0) {
					console.log(res.data.itemList,'//////');
					let arr = res.data.itemList
					if (this.tree === []) {
						this.classify = arr;
					} else {
						console.log(this.tree,arr,'this.tree');
						var firstTree = this.handleChild(arr, this.tree);
						console.log(firstTree,'firstTreefirstTreefirstTree');
						firstTree.map(tree1 => {
							this.tree.map(limit1 => {
								if (tree1.code == limit1.code) {
									tree1.itemList = this.handleChild(tree1.itemList, limit1.children);
									tree1.itemList.map(tree2 => {
										if (!Object.prototype.hasOwnProperty.call(limit1, 'children') || limit1.children == null || limit1.children.length == 0) {
											tree2.itemList = this.handleChild(tree2.itemList, null);
										} else {
											limit1.children.map(limit2 => {
												if (tree2.code == limit2.code) {
													tree2.itemList = this.handleChild(tree2.itemList, Object.prototype.hasOwnProperty.call(limit2, 'children') ? limit2.children : null);
												}
											});
										}
									})
								}
							})
						});
						this.classify = firstTree;
					}
					console.log(this.classify,'this.classifythis.classifythis.classifythis.classify');
					this.changeNav1(this.classify[0])
				}
			})
		},
		handleChild(arr1, arr2) {
			var result = arr1;
			if (!this.$public.isNull(arr2)) {
				result = arr1.filter(value => {
					return arr2.some(item => {
						return item.code == value.code
					});
				});
			}
			return result;
		},
		closeTag(index) {
			this.selectedArr.splice(index, 1)
		},
		// 改变关键字
		changeKeyword() {
			this.page.pageIndex = 1;
			this.getTableData();
		},
		// 改变 猫猫狗狗
		changeApplyType() {
			this.page.pageIndex = 1;
			this.getTableData();
		},
		// 分页
		changePage(num) {
			this.page.pageIndex = num
			this.getTableData();
		},
		handleSizeChange(val) {
			this.page.pageIndex = 1;
			this.page.pageSize = val
			this.getTableData()
		},
		// 获取商品
		getTableData() {
			if (this.code1 === "goodstype") {          // 商品
				let params = {
					"applyType": this.applyType,  // 宠物类型 0:通用 1:猫猫 2:狗 3:异常
					"categoryId": this.categoryId,  // 商品类型编号
					"keyword": this.keyword,
					"pageIndex": this.page.pageIndex,
					"pageSize": this.page.pageSize,
					"sortIndex": 0,
					"ignoreGoods": this.ignoreGoods
				}
				let loading = this.$loading()
				this.$http.post("/product/goods/stock/list", params).then(res => {
					loading.close()
					if (res.code === 0) {
						this.tableDataGoods = res.data.list
						this.page.total = res.data.count
						this.initTableRowsSelected(res.data.list)
					}
				})
			} else if (this.code1 === "servicetype") {  //  服务
				let params = {
					weight: 0,
					"applyType": this.applyType,  // 宠物类型 0:通用 1:猫猫 2:狗 3:异常
					"categoryId": this.categoryId,  // 商品类型编号
					"keyword": this.keyword,
					"pageIndex": this.page.pageIndex,
					"pageSize": this.page.pageSize,
				}
				let loading = this.$loading()
				this.$http.post("/product/life/service/List", params).then(res => {
					loading.close()
					if (res.code === 0) {
						this.tableDataService = res.data.list
						this.page.total = res.data.count
						this.initTableRowsSelected(res.data.list)
					}
				})
			} else if (this.code1 === "combotype") {    // 套餐
				let params = {
					"applyType": this.applyType,  // 宠物类型 0:通用 1:猫猫 2:狗 3:异常
					"categoryId": this.categoryId,  // 商品类型编号
					"keyword": this.keyword,
					"pageIndex": this.page.pageIndex,
					"pageSize": this.page.pageSize,
				}
				let loading = this.$loading()
				this.$http.post("/product/goods/group/list", params).then(res => {
					loading.close()
					if (res.code === 0) {
						this.tableDataGroup = res.data.list
						this.page.total = res.data.count
						this.initTableRowsSelected(res.data.list)
					}
				})
			} else if (this.code1 === "virtualtype") {  // 虚拟
				let params = {
					status: 0,
					weight: 0,
					"applyType": this.applyType,  // 宠物类型 0:通用 1:猫猫 2:狗 3:异常
					"categoryId": this.categoryId,  // 商品类型编号
					"keyword": this.keyword,
					"pageIndex": this.page.pageIndex,
					"pageSize": this.page.pageSize,
				}
				let loading = this.$loading()
				this.$http.post("/product/virtual/card/List", params).then(res => {
					loading.close()
					if (res.code === 0) {
						this.tableDataVirtual = res.data.list
						this.page.total = res.data.count
						this.initTableRowsSelected(res.data.list)
					}
				})
			} else if (this.code1 === "coupontype") {   //  优惠券
				let params = {
					"applyType": this.applyType,  // 宠物类型 0:通用 1:猫猫 2:狗 3:异常
					"categoryId": this.categoryId,  // 商品类型编号
					"keyword": this.keyword,
					"pageIndex": this.page.pageIndex,
					"pageSize": this.page.pageSize,
				}
				let loading = this.$loading()
				this.$http.post("/product/virtual/coupons/list", params).then(res => {
					loading.close()
					if (res.code === 0) {
						this.tableDataCoupon = res.data.list
						this.page.total = res.data.count
						this.initTableRowsSelected(res.data.list)
					}
				})
			} else if (this.code1 === 'pettype') { // 宠物-----------------------
				let params = {
					"status": '1',  // 宠物类型 0:通用 1:猫猫 2:狗 3:异常
					"categoryId": this.categoryId,  // 商品类型编号
					"keyword": this.keyword,
					"pageIndex": this.page.pageIndex,
					"pageSize": this.page.pageSize,
				}
				let loading = this.$loading()
				this.$http.post("/product/pet/list", params).then(res => {
					loading.close()
					if (res.code === 0) {
						this.tableDataPet = res.data.list
						this.page.total = res.data.count
						this.initTableRowsSelected(res.data.list)
					}
				})
			}
		},
		changeCheckbox(row) {
			console.log(row,'row');
			if (this.code1 === "goodstype") {
				let index = -1;
				for (let i = 0; i < this.selectedArr.length; i++) {
					if (row.goodsId === this.selectedArr[i].newData.productId) {
						index = i
					}
				}
				if (index > -1) {
					this.selectedArr.splice(index, 1)
				} else {
					this.selectedArr.push({
						originalData: row,
						newData: {
							"productId": row.goodsId,
							"productName": row.title,
							"productType": 1,  // 产品类型 1 商品  2 服务 3 消费卡 4套餐
							"brand": row.brand.name,
							"firstCategoryId": row.firstCategoryInfo.id,
							"firstCategoryName": row.firstCategoryInfo.name,
							"secondCategoryId": row.secondCategoryInfo.id,
							"secondCategoryName": row.secondCategoryInfo.name,
							"modelValue": row.model,
							"unit": "",
							"currentCostPrice":row.currentCostPrice,
							"originalPrice": row.salePrice,
							"price": row.salePrice,
							"costPrice": '',
							"num": 1,
							"activityId": "",
							"activityName": "",
							"activityType": 0, //  活动类型  1 活动 2 消费卡 3抵用券
							"activityItem": {},
							"payType": "",  // 
							"remark": "",
							"isGive": false,  // 是否赠送
							"buyBack": row.buyBack,  // 是否复购
							tableKey: row.goodsId + Math.random(),
							"petId": "",
							"petName": "",
							"petClass": -1,
							"staffIds": [],
							"salesList": [],
							"productDetails": [],
							"barCode": row.barCode,
							"currentStock": row.currentCount,
							"count": 1,
							"totalAmount": 0,
						},
					})
				}
			} else if (this.code1 === "servicetype") {
				let index = -1;
				for (let i = 0; i < this.selectedArr.length; i++) {
					if (row.serviceId === this.selectedArr[i].newData.productId) {
						index = i
					}
				}
				if (index > -1) {
					this.selectedArr.splice(index, 1)
				} else {
					console.log(row.salePrice,'rowrowrow');
					this.selectedArr.push({
						originalData: row,
						newData: {
							"productId": row.serviceId,
							"productName": row.title,
							"productType": 2,  // 产品类型 1 商品  2 服务 3 消费卡 4套餐
							"brand": '',
							"firstCategoryId": row.firstCategoryInfo.id,
							"firstCategoryName": row.firstCategoryInfo.name,
							"secondCategoryId": row.secondCategoryInfo.id,
							"secondCategoryName": row.secondCategoryInfo.name,
							"modelValue": '',
							"unit": "",
							"originalPrice": row.salePrice,
							"price": row.salePrice,
							"costPrice": 0,
							"num": 1,
							"activityId": "",
							"activityName": "",
							"activityType": 0, //  活动类型  1 活动 2 消费卡 3抵用券
							"activityItem": {},
							"payType": "",  // 
							"remark": "",
							"isGive": false,  // 是否赠送
							"buyBack": row.buyBack,  // 是否复购
							tableKey: row.serviceId + Math.random(),
							"petId": "",
							"petName": "",
							"petClass": -1,
							"staffIds": [],
							"salesList": [],
							"productDetails": [],
							"weightDetail": row.weightDetail,
							'barCode': row.barCode
						},
					})
				}
			} else if (this.code1 === "combotype") {
				let index = -1;
				for (let i = 0; i < this.selectedArr.length; i++) {
					if (row.groupId === this.selectedArr[i].newData.productId) {
						index = i
					}
				}
				if (index > -1) {
					this.selectedArr.splice(index, 1)
				} else {
					let productDetails = []
					for (let i = 0; i < row.groupItems.length; i++) {
						productDetails.push({
							"productId": row.groupItems[i].goodsId,
							"productName": row.groupItems[i].title,
							"productType": 4,  // 产品类型 1 商品  2 服务 3 消费卡 4套餐
							"num": row.groupItems[i].count,
							"price": row.groupItems[i].salePrice,
							"originalPrice": row.groupItems[i].salePrice,
							"unit": "",
							"modelName": row.groupItems[i].model,
							"brand": row.groupItems[i].brand,
							"firstCategoryId": row.groupItems[i].firstCategoryInfo ? row.groupItems[i].firstCategoryInfo.id : '',
							"firstCategoryName": row.groupItems[i].firstCategoryInfo ? row.groupItems[i].firstCategoryInfo.name : '',
							"secondCategoryId": row.groupItems[i].secondCategoryInfo ? row.groupItems[i].secondCategoryInfo.id : '',
							"secondCategoryName": row.groupItems[i].secondCategoryInfo ? row.groupItems[i].secondCategoryInfo.name : '',

							tableKey: row.groupItems[i].goodsId + Math.random(),
						})
					}
					this.selectedArr.push({
						originalData: row,
						newData: {
							"productId": row.groupId,
							"productName": row.title,
							"productType": 4,  // 产品类型 1 商品  2 服务 3 消费卡 4套餐
							"brand": '',
							"firstCategoryId": row.firstCategoryInfo.id,
							"firstCategoryName": row.firstCategoryInfo.name,
							"secondCategoryId": row.secondCategoryInfo.id,
							"secondCategoryName": row.secondCategoryInfo.name,
							"modelValue": '',
							"unit": "",
							"originalPrice": row.salePrice,
							"price": row.salePrice,
							"costPrice": 0,
							"num": 1,
							"activityId": "",
							"activityName": "",
							"activityType": 0, //  活动类型  1 活动 2 消费卡 3抵用券
							"activityItem": {},
							"payType": "",  // 
							"remark": "",
							"isGive": false,  // 是否赠送
							"buyBack": false,  // 是否复购
							tableKey: row.groupId + Math.random(),
							"petId": "",
							"petName": "",
							"petClass": -1,
							"staffIds": [],
							"salesList": [],
							"productDetails": productDetails,
							'barCode': row.barCode
						},
					})
				}
			} else if (this.code1 === "virtualtype") {
				let index = -1;
				for (let i = 0; i < this.selectedArr.length; i++) {
					if (row.virtualId === this.selectedArr[i].newData.productId) {
						index = i
					}
				}
				if (index > -1) {
					this.selectedArr.splice(index, 1)
				} else {
					this.selectedArr.push({
						originalData: row,
						newData: {
							"productId": row.virtualId,
							"productName": row.title,
							"productType": 3,  // 产品类型 1 商品  2 服务 3 消费卡 4套餐
							"brand": '',
							"firstCategoryId": row.firstCategoryInfo.id,
							"firstCategoryName": row.firstCategoryInfo.name,
							"secondCategoryId": row.secondCategoryInfo.id,
							"secondCategoryName": row.secondCategoryInfo.name,
							"modelValue": '',
							"unit": "",
							"originalPrice": row.salePrice,
							"price": row.salePrice,
							"costPrice": 0,
							"num": 1,
							"activityId": "",
							"activityName": "",
							"activityType": 0, //  活动类型  1 活动 2 消费卡 3抵用券
							"activityItem": {},
							"payType": "",  // 
							"remark": "",
							"isGive": false,  // 是否赠送
							"buyBack": false,  // 是否复购
							tableKey: row.virtualId + Math.random(),
							"petId": "",
							"petName": "",
							"petClass": -1,
							"staffIds": [],
							"salesList": [],
							"productDetails": [],
							'barCode': row.barCode
						},
					})
				}
			} else if (this.code1 === "coupontype") {
				let index = -1;
				for (let i = 0; i < this.selectedArr.length; i++) {
					if (row.virtualId === this.selectedArr[i].newData.productId) {
						index = i
					}
				}
				if (index > -1) {
					this.selectedArr.splice(index, 1)
				} else {
					this.selectedArr.push({
						originalData: row,
						newData: {
							"productId": row.virtualId,
							"productName": row.title,
							"productType": -100,  // 产品类型 1 商品  2 服务 3 消费卡 4套餐  5套餐 
							"brand": '',
							"firstCategoryId": row.firstCategoryInfo.id,
							"firstCategoryName": row.firstCategoryInfo.name,
							"secondCategoryId": row.secondCategoryInfo.id,
							"secondCategoryName": row.secondCategoryInfo.name,
							"modelValue": '',
							"unit": "",
							"originalPrice": 0,
							"price": 0,
							"costPrice": 0,
							"num": 1,
							"activityId": "",
							"activityName": "",
							"activityType": 0, //  活动类型  1 活动 2 消费卡 3抵用券
							"activityItem": {},
							"payType": "",  // 
							"remark": "",
							"isGive": false,  // 是否赠送
							"buyBack": false,  // 是否复购
							tableKey: row.virtualId + Math.random(),
							"petId": "",
							"petName": "",
							"petClass": -1,
							"staffIds": [],
							"salesList": [],
							"productDetails": [],
						},
					})
				}
			}else if (this.code1 === "pettype") {
				let index = -1;
				for (let i = 0; i < this.selectedArr.length; i++) {
					if (row.petId === this.selectedArr[i].newData.productId) {
						index = i
						
					}
				}
				if (index > -1) {
					this.selectedArr.splice(index, 1)
				} else {
					this.selectedArr.push({
						originalData: row,
						newData: {
							"productId": row.petId,
							"productName": row.nickName,
							"productType": 5,  // 产品类型 1 商品  2 服务 3 消费卡 4套餐  5套餐 
							"brand": '',
							"firstCategoryId": row.firstCategoryInfo.id,
							"firstCategoryName": row.firstCategoryInfo.name,
							"secondCategoryId": row.secondCategoryInfo.id,
							"secondCategoryName": row.secondCategoryInfo.name,
							"modelValue": '',
							"unit": "",
							"originalPrice":row.salePrice,
							"price": row.salePrice,
							"costPrice": 0,
							"num": 1,
							"activityId": "",
							"activityName": "",
							"activityType": 0, //  活动类型  1 活动 2 消费卡 3抵用券
							"activityItem": {},
							"payType": "",  // 
							"remark": "",
							"isGive": false,  // 是否赠送
							"buyBack": false,  // 是否复购
							tableKey: row.virtualId + Math.random(),
							"petId": "",
							"petName": "",
							"petClass": -1,
							"staffIds": [],
							"salesList": [],
							"productDetails": [],
						},
					})
				}
			}
		},
		initTableRowsSelected(list) {
			if (this.code1 === "goodstype") {
				for (let i = 0; i < list.length; i++) {
					for (let a = 0; a < this.selectedArr.length; a++) {
						if (list[i].goodsId === this.selectedArr[a].newData.productId) {
							this.selectedArr[a].originalData = list[i]
						}
					}
				}
			} else if (this.code1 === "servicetype") {
				for (let i = 0; i < list.length; i++) {
					for (let a = 0; a < this.selectedArr.length; a++) {
						if (list[i].serviceId === this.selectedArr[a].newData.productId) {
							this.selectedArr[a].originalData = list[i]
						}
					}
				}
			} else if (this.code1 === "combotype") {
				for (let i = 0; i < list.length; i++) {
					for (let a = 0; a < this.selectedArr.length; a++) {
						if (list[i].groupId === this.selectedArr[a].newData.productId) {
							this.selectedArr[a].originalData = list[i]
						}
					}
				}
			} else if (this.code1 === "virtualtype") {
				for (let i = 0; i < list.length; i++) {
					for (let a = 0; a < this.selectedArr.length; a++) {
						if (list[i].virtualId === this.selectedArr[a].newData.productId) {
							this.selectedArr[a].originalData = list[i]
						}
					}
				}
			} else if (this.code1 === "coupontype") {
				for (let i = 0; i < list.length; i++) {
					for (let a = 0; a < this.selectedArr.length; a++) {
						if (list[i].virtualId === this.selectedArr[a].newData.productId) {
							this.selectedArr[a].originalData = list[i]
						}
					}
				}
			}else if (this.code1 === "pettype") {
				for (let i = 0; i < list.length; i++) {
					for (let a = 0; a < this.selectedArr.length; a++) {
						if (list[i].petId === this.selectedArr[a].newData.productId) {
							this.selectedArr[a].originalData = list[i]
						}
					}
				}
			}
		},
	}
};
</script>
<style lang="scss" scoped>
.classify1 {
	display: flex;
	align-items: center;

	.classify1Item {
		font-size: 17px;
		color: black;
		margin: 5px 5px;
		cursor: pointer;
		border-bottom: 1px solid #F2F2F2;
		border-radius: 30px;
		padding: 3px 10px;
		background-color: #ece9e9;
		text-align: center;

		&.active {
			background-color: $mainColor;
			color: #fff;
		}
	}
}

.HSDialogContent {
	width: 1350px;
	display: flex;
	height: 600px;


	// height:760px;
	.contentL {
		width: 300px;
		background-color: #fafafa;
		flex-shrink: 0;
		height: 100%;


		.classifyBox {
			height: calc(100% - 53px);
			overflow: auto;

			.classify2 {
				padding-left: 20px;

				.classify2Item {
					line-height: 46px;
					border-bottom: 1px solid #f2f2f2;
					font-size: 16px;
					cursor: pointer;

					&.active {
						color: $mainColor;
					}
				}

				.classify3 {
					display: flex;
					flex-wrap: wrap;
					padding-left: 10px;
					font-size: 14px;
					cursor: pointer;

					.classify3Item {
						line-height: 42px;
						margin: 0 5px;

						&.active {
							color: $mainColor;
						}
					}
				}
			}
		}
	}

	.contentR {
		width: 1050px;
		background-color: #fff;
		position: relative;
		padding-bottom: 142px;
		box-sizing: border-box;

		.productTableBox {
			height: calc(100% - 60px);
		}

		.paging {
			width: 100%;
			bottom: 90px;
		}

		.selectedProduct {
			height: 90px;
			background-color: #fafafa;
			border-radius: 4px;
			padding: 12px;
			box-sizing: border-box;
			margin: 0 10px;
			position: absolute;
			bottom: 0;
			width: calc(100% - 24px);
		}
	}

	.PetList {
		display: flex;
		// border: 1px solid gold;
		flex-wrap: wrap;
		background: rgb(249, 249, 249) !important;
		// width: 100%;
		.petItem {
			flex: 0 0 calc(33.33% - 20px);
			border: 1px solid greenyellow;
			padding: 1px 3px;
			display: flex;
			align-items: center;
			height: 118px;
			border-radius: 6px;
			background: #fff;
			border: 1px solid #fff;
			margin: 5px 5px;
			cursor: pointer;

			.petInfo {
				width: calc(100% - 133px);
				color: #333336;
				line-height: 25px;
				font-size: 13px;
				margin-left: 10px;
			}

			.petInfoT {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		}
		.isActive{
			background-color: gold !important;
		}

		.petItem:hover {
			border-color: gold;
		}
	}
}
</style>
